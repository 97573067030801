import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cidadeFilter',
    pure: false
})
export class cidadeFilterPipe implements PipeTransform {
    transform(items: Array<any>, cidade: string): any {
        if(items){
            return items.filter(item => item.cidade === cidade);
        }
    }
}
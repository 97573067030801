import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DataBaseService {
   urlDataBase:any = "https://sa-east-1.aws.data.mongodb-api.com/app/application-0-ugppx/endpoint/http/get";
   apiSMS:any = "https://sa-east-1.aws.data.mongodb-api.com/app/application-0-ugppx/endpoint/sms/smsApi";
   API_URL_SAUDADE:any = this.urlDataBase+'?coll=saudade';
   API_URL_BAILE:any = this.urlDataBase+'?coll=baile';
   API_URL_CIDADES:any = this.urlDataBase+'?coll=cidades';
   API_URL_COLACAO:any = this.urlDataBase+'?coll=colacao';
   API_URL_CURSOS:any = this.urlDataBase+'?coll=todoscursos';
   API_URL_EVENTOS:any = this.urlDataBase+'?coll=eventos';
   API_URL_INSTITUICOES:any = this.urlDataBase+'?coll=instituicoes';
   API_URL_JANTAR:any = this.urlDataBase+'?coll=jantar';
   API_URL_MISSA:any = this.urlDataBase+'?coll=missa';
   API_URL_GALERIAS:any = this.urlDataBase+'?coll=galerias';
   API_URL_IMAGENS:any = this.urlDataBase+'?coll=imagens';
   API_URL_LOGS:any = this.urlDataBase+'?coll=logs';
   API_URL_MINHAFORMATURA:any = this.urlDataBase+'?coll=minhaformatura';
   API_URL_CONFIGS:any = this.urlDataBase+'?coll=configs';
   API_URL_FOTOS:any = this.urlDataBase+'?coll=fotos';

   constructor(private http:HttpClient){}

   getFotos(){
      return this.http.get(this.API_URL_FOTOS+'&method=GET');
   }

   getInstituicoesByCidade(key:any){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=GET&filtro={"cidade":"'+key+'","tipo":"UNIVERSIDADE"}')
   }
     
   getAssessoria(){
      return this.http.get(this.API_URL_CONFIGS+'&method=GET&filtro={"nome":"assessoria"}')
   }

   getSms(){
      return this.http.get(this.API_URL_CONFIGS+'&method=GET&filtro={"nome":"sms"}')
   }

   getEventos(){
      return this.http.get(this.API_URL_EVENTOS+'&method=GET');
   }

   getCursos(){
      return this.http.get(this.API_URL_CURSOS+'&method=GET');
   }

   getEventosById(id:any){
      return this.http.get(this.API_URL_EVENTOS+'&method=GET&id='+id);
   }

   getEventosByCidade(key:any){
      return this.http.get(this.API_URL_EVENTOS+'&method=GET&filtro={"cidade":"'+key+'"}');
   }

   postLogs(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_LOGS+'&method=POST&body='+body);
   }

   getBaile(){
      return this.http.get(this.API_URL_BAILE+'&method=GET&sort={"titulo":1}');
   }

   getBaileById(id:any){
      return this.http.get(this.API_URL_BAILE+'&method=GET&id='+id);
   }

   getJantar(){
      return this.http.get(this.API_URL_JANTAR+'&method=GET&sort={"titulo":1}');
   }

   getJantarById(id:any){
      return this.http.get(this.API_URL_JANTAR+'&method=GET&id='+id);
   }

   getColacao(){
      return this.http.get(this.API_URL_COLACAO+'&method=GET&sort={"titulo":1}');
   }

   getColacaoById(id:any){
      return this.http.get(this.API_URL_COLACAO+'&method=GET&id='+id);
   }

   getMissa(){
      return this.http.get(this.API_URL_MISSA+'&method=GET&sort={"titulo":1}');
   }

   getMissaById(id:any){
      return this.http.get(this.API_URL_MISSA+'&method=GET&id='+id);
   }

   getSaudade(){
      return this.http.get(this.API_URL_SAUDADE+'&method=GET&sort={"titulo":1}');
   }

   getSaudadeById(id:any){
      return this.http.get(this.API_URL_SAUDADE+'&method=GET&id='+id);
   }

   getGaleriaByName(galeria:any){
      return this.http.get(this.API_URL_IMAGENS+'&method=GET&filtro={"galeria":"'+galeria+'"}')
   }

   getGalerias(){
      return this.http.get(this.API_URL_GALERIAS+'&method=GET&sort={"galeria":1}')
   }

   getGaleriaById(id:any){
      return this.http.get(this.API_URL_GALERIAS+'&method=GET&id='+id);
    }

   getCidades(){
      return this.http.get(this.API_URL_CIDADES+'&method=GET')
   }

   getMissaByTipo(key:any){
     return this.http.get(this.API_URL_MISSA+'&method=GET&filtro={"tipo":"'+key+'"}')
   }

   getColacaoByTipo(key:any){
      return this.http.get(this.API_URL_COLACAO+'&method=GET&filtro={"tipo":"'+key+'"}')
    }

   getSaudadeByTipo(key:any){
      return this.http.get(this.API_URL_SAUDADE+'&method=GET&filtro={"tipo":"'+key+'"}')
    }

   getJantarByTipo(key:any){
      return this.http.get(this.API_URL_JANTAR+'&method=GET&filtro={"tipo":"'+key+'"}')
   }

   getBaileByTipo(key:any){
      return this.http.get(this.API_URL_BAILE+'&method=GET&filtro={"tipo":"'+key+'"}')
   }

   postMinhaFormatura(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_MINHAFORMATURA+'&method=POST&body='+body);
   }

   envioSMS(numero:any,codigo:any){
      let msg = "Minha Formatura - Seu Codigo de Acesso: "+codigo ;
      let objRequest = {
         numero:numero,
         mensagem:msg
      }
      let str = JSON.stringify(objRequest);
      return this.http.get(this.apiSMS+'?dados='+str);
   }

   envioSMSTrends(numero:any,msg:any){
      let objRequest = {
        numero:numero,
        mensagem:msg
      }
      let str = JSON.stringify(objRequest);
      return this.http.get(this.apiSMS+'?dados='+str);
   }

   getFotosFormatura(){
      return this.http.get(this.API_URL_IMAGENS+'&method=GET');
   }
   
}
<div class="modalBoasVindas" id="welcome">
  <div class="bg">
      <div class="content" [style.padding]="!isDesktop ? '10px' : '50px'">
          <img class="img-fluid" [style.width]="!isDesktop ? '100%' : '400px'" src="../../../assets/img/logo-minha-formatura.png">
          <br>
          <p [style.fontSize]="!isDesktop ? '1.2em' : '18px'" [style.padding]="!isDesktop ? '0px' : '2em'">Com a Trends você realizará o projeto da sua Formatura, selecionando todos os itens que irá tornar esse momento ainda mais único e inesquecível!</p>
          <button class="btn" [ngClass]="!isDesktop ? 'btn-lg' : ''" (click)="fecharBoasVindas()">COMEÇAR!</button>
      </div>
  </div>
</div>
<div class="modalFinal" id="modalFim">
  <div class="bg">
    <div class="content" [style.padding]="!isDesktop ? '10px' : '50px'">
        <img class="img-fluid" [style.width]="!isDesktop ? '100%' : '50%'" src="../../../assets/img/logo-minha-formatura.png">
        <p [style.fontSize]="!isDesktop ? '1.2em' : '2em'">
          <strong>PARABÉNS</strong><br>
          Você deu um passo para realizar a formatura dos seus sonhos! Entraremos em contato através do telefone cadastrado.
        </p>
        <button class="btn" [ngClass]="!isDesktop ? 'btn-lg' : ''" (click)="fim()">FINALIZAR!</button>
    </div>
</div>
</div>
<div id="principal" class="mainDiv">
  <div class="row topoLogo no-print">
    <div class="col col-12">
        <img src="../assets/img/logo-minha-formatura.png">
    </div>
  </div>
  <mat-horizontal-stepper linear="true" #stepper class="full-width"> 
  <mat-step [stepControl]="detalhes" [editable]="!block">
    <form [formGroup]="detalhes">
      <div class="row dados">
        <div class="col col-12 col-md-6">
              <label>Cidade</label>
              <select formControlName="cidadeEscolhida" (change)="selecionarCidade(detalhes.get('cidadeEscolhida').value)" [ngClass]="{'is-invalid':!detalhes.controls.cidadeEscolhida.valid}" class="form-control">
                <option [selected] value="">SELECIONAR</option>
                <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                  {{cidade?.nome}}
                </option>
              </select>
        </div>
        <div class="col col-12 col-md-6" *ngIf="cidadeCheck">
                <label>Instituição</label>
                <select formControlName="instituicoes" [ngClass]="{'is-invalid':!detalhes.controls.instituicoes.valid}" class="form-control">
                 <option [selected] value="">SELECIONAR</option>
                  <option *ngFor="let facul of listaInstituicoes" [value]="facul?.nome">
                    {{facul?.nome}}
                  </option>
                </select>
        </div>
        <div class="col col-12 col-md-12" *ngIf="detalhes.controls.instituicoes.valid">
          <label>Curso</label>
          <select formControlName="curso" [ngClass]="{'is-invalid':!detalhes.controls.curso.valid}" class="form-control">
            <option [selected]>SELECIONAR</option>
            <option *ngFor="let curso of listaCursos" [value]="curso?.nome">
                {{curso?.nome}}
              </option>
              <option value="OUTROS">OUTROS</option>
            </select>
        </div>
      </div>
      <div class="row dados" [hidden]="!detalhes.controls.curso.valid">
        <div class="col col-6 col-md-2">
            <label>Turma</label>
            <input type="text" placeholder="Ex.: A" formControlName="turma" [ngClass]="{'is-invalid':!detalhes.controls.turma.valid}" class="form-control"  oninput="this.value = this.value.toUpperCase()">
        </div>
        <div class="col col-6 col-md-3">
          <label>Sala</label>
          <input type="text" placeholder="Ex.: 35" formControlName="sala" [ngClass]="{'is-invalid':!detalhes.controls.sala.valid}" class="form-control" oninput="this.value = this.value.toUpperCase()">
        </div>
        <div class="col col-6 col-md-7">
          <label>Periodo</label>
          <select formControlName="periodo" [ngClass]="{'is-invalid':!detalhes.controls.periodo.valid}" class="form-control">
            <option [selected] value=""></option>
            <option value="1º Periodo">1º Periodo</option>
            <option value="2º Periodo">2º Periodo</option>
            <option value="3º Periodo">3º Periodo</option>
            <option value="4º Periodo">4º Periodo</option>
            <option value="5º Periodo">5º Periodo</option>
            <option value="6º Periodo">6º Periodo</option>
            <option value="7º Periodo">7º Periodo</option>
            <option value="8º Periodo">8º Periodo</option>
            <option value="9º Periodo">9º Periodo</option>
            <option value="10º Periodo">10º Periodo</option>
          </select>
        </div>
        <div class="col col-6 col-md-3">
          <label>Turno</label>
          <select formControlName="periodoTurma" [ngClass]="{'is-invalid':!detalhes.controls.periodoTurma.valid}" class="form-control">
            <option [selected] value=""></option>
            <option value="MATUTINO">MATUTINO</option>
            <option value="VESPERTINO">VESPERTINO</option>
            <option value="NOTURNO">NOTURNO</option>
            <option value="INTEGRAL">INTEGRAL</option>
          </select>
        </div>
        <div class="col col-6 col-md-2">
          <label>Formandos</label>
          <input type="number" class="form-control" formControlName="qtdeFormandos" [ngClass]="{'is-invalid':!detalhes.controls.qtdeFormandos.valid}" [readonly]="block">
        </div>
        <div class="col col-6 col-md-2">
          <label>Dias de Evento</label>
          <select (change)="selecionarDiaEvento()" formControlName="diasEvento" class="form-control" [ngClass]="{'is-invalid':!detalhes.controls.diasEvento.valid}">
            <option value=1>1</option>
            <option value=2>2</option>
          </select>
      </div>
      <div class="col col-7 col-md-3">
        <label>Data da Formatura</label>
        <input type="date" class="form-control" formControlName="dataFim" (change)="diferencaMeses()"  [ngClass]="{'is-invalid':!detalhes.controls.dataFim.valid}">
      </div>
      <div class="col col-5 col-md-2">
        <label>Prestações</label>
        <input type="number" class="form-control" name="prestacoes" [ngModel]="prestacoes" [ngModelOptions]="{standalone: true}" readonly>
      </div>
    </div>
    <hr>
    <div class="stepperButtons">
        <button class="btn btn-info" matStepperNext [disabled]="this.detalhes.invalid" (click)="toTop()">Próximo</button>
    </div>
    </form>             
  </mat-step>
  <mat-step [stepControl]="dados" [editable]="!block">
    <div style="width: 80%;margin:0 auto">
    <div class="row">
      <div class="col col-12">
        <label>Nome Completo</label>
        <input type="text" class="form-control" [(ngModel)]="nomeVisitante" oninput="this.value = this.value.toUpperCase()">
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col col-12">
        <label>E-mail</label>
        <input type="email" class="form-control" [(ngModel)]="emailVisitante">
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col col-12">
        <label>Celular</label>
        <input type="text" class="form-control" [(ngModel)]="celularVisitante" [textMask]="{mask: MASKS.telefone.textMaskFunction}">
      </div>
    </div>
    <!-- <div class="row" *ngIf="!tokenEnviado" style="text-align: center; padding:20px">
      <div class="col">
        <button class="btn btn-info" (click)="enviarSMS()" [disabled]="enviando || !nomeVisitante || !celularVisitante">
          <span *ngIf="!enviando">
            ENVIAR CÓDIGO
          </span>
          <span *ngIf="enviando" style="text-align:center">
            <i class="fa fa-spinner fa-spin"></i>
          </span>
          </button>
      </div>
    </div> -->
    <div class="row" style="text-align:center;padding:20px">
      <div class="col">  
      <button class="btn btn-info" (click)="puxarPrecos();toTop()" matStepperNext [disabled]="enviando || !nomeVisitante || !celularVisitante">
        AVANÇAR
      </button>
    </div>
    </div>
    



    <br>
    <div class="row" *ngIf="tokenEnviado">
      <div class="col col-12">
        <input type="number" style="font-size:2em;text-align: center;" [(ngModel)]="codigoToken" placeholder="Código SMS" class="form-control">
      </div>
    </div>
    <div class="row" *ngIf="tokenEnviado" style="text-align: center; padding:20px">
      <div class="col col-12 col-md-6" style="margin-bottom:20px">
        <button class="btn btn-danger btn-lg btn-block" (click)="enviarSMS()" [disabled]="countdown != 59 || (!nomeVisitante || !celularVisitante)">
          <span *ngIf="countdown != 59">
            REENVIAR SMS (00:{{countdown}})
          </span>
          <span *ngIf="countdown == 59">
            REENVIAR SMS
          </span>
        </button>  
      </div>
      <div class="col col-12 col-md-6">
        <button class="btn btn-info btn-lg btn-block" (click)="puxarPrecos();toTop()" [disabled]="codigoToken != token" matStepperNext>CONFIRMAR</button>
      </div>
    </div>
  </div>
  </mat-step>
  <mat-step [stepControl]="selecao">
    <div style="text-align: center;color:#ff2c96">
      <h2>Selecione os Eventos Desejados</h2>
    </div>
    <div class="cards">
      <div class="card">
        <img class="card-img-top" src="../assets/img/saudade-tag.png">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="cardCheck1" [(ngModel)]="checkSaudade">
          <label class="form-check-label" for="cardCheck1">Rolê Pré-Evento</label>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="../assets/img/missa-tag.png">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="cardCheck2" [(ngModel)]="checkMissa">
          <label class="form-check-label" for="cardCheck2">Missa / Culto Ecumênico</label>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="../assets/img/colacao-tag.png">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="cardCheck3" [(ngModel)]="checkColacao">
          <label class="form-check-label" for="cardCheck3">Colação de Grau</label>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="../assets/img/jantar-tag.png">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="cardCheck4" [(ngModel)]="checkjantar">
          <label class="form-check-label" for="cardCheck4">Jantar Dançante</label>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="../assets/img/baile-tag.png">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="cardCheck5" [(ngModel)]="checkBaile">
          <label class="form-check-label" for="cardCheck5">Baile de Gala</label>
        </div>
      </div>
    </div>
    <br>
    <div class="stepperButtons">
      <button class="btn btn-dark" matStepperPrevious (click)="toTop()">Anterior</button>
      &nbsp;&nbsp;
      <button class="btn btn-info" matStepperNext (click)="toTop()">Próximo</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="aulaSaudade" *ngIf="checkSaudade">
    <div style="text-align: center;color:#ff2c96">
      <h2>Aula da Saudade</h2>
    </div>
    <div class="row fotoGaleria">
      <div class="col">
        <img [src]="fotoSaudade">
      </div>
    </div>  
    <form>
      <!-- SELECT OPTION -->
      <div *ngFor="let item of aulaSaudadeSelecionavel">
            <label>{{item?.titulo}}</label>
            <select (change)="aSaudadeSomaSelecionavel($event,item?.posicao)" class="form-control">
              <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
              <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
               <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
               <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
              </optgroup>
            </select>
          <hr>
      </div>
      <!-- CHECKBOX -->
      <div *ngFor="let item of aulaSaudadeCheckbox; let i = index" style="font-size:1.2em; padding:5px">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'aulaCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado" [value]="vlr?.valor" (change)="aSaudadeSoma($event, 'nao',item?.posicao)">
            <label class="custom-control-label" [for]="i+'aulaCheck'" style="cursor: pointer;">
              {{item?.titulo}}<br>
              <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
            </label>
        </div>
        <br>
      </div>
      <div class="stepperButtons">
        <button class="btn btn-dark" matStepperPrevious (click)="toTop()">Anterior</button>
        &nbsp;&nbsp;
        <button class="btn btn-info" matStepperNext (click)="toTop()">Próximo</button>
      </div>
    </form>     
  </mat-step> 
  <mat-step [stepControl]="missa" *ngIf="checkMissa">
    <div style="text-align: center;color:#ff2c96">
      <h2>Missa</h2>
    </div>
    <div class="row fotoGaleria">
      <div class="col">
        <img [src]="fotoMissa">
      </div>
    </div> 
      <form>
  <!-- SELECT OPTION -->
  <div *ngFor="let item of missaSelecionavel">
    <label>{{item?.titulo}}</label>
    <select (change)="aMissaSomaSelecionavel($event,item?.posicao)" class="form-control">
      <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
      <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
       <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
       <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
      </optgroup>
    </select>
  <div class="descricaoSelecionavel">{{itemOpt?.descricao | replace}}</div>
  <hr>
  </div>
  <!-- CHECKBOX FIXOS-->
  <div *ngFor="let item of fixosMissa; let i = index" style="font-size:1.2em; padding:5px">
    <div class="custom-control custom-checkbox">
      <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'missaFixo'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado" [value]="vlr?.valor" (change)="somarFixosMissa($event,'nao',item?.posicao)">
        <label class="custom-control-label" [for]="i+'missaFixo'" style="cursor: pointer;">
          {{item?.titulo}}<br>
          <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
        </label>
    </div>
    <br>
  </div>
  <!-- CHECKBOX -->
  <div *ngFor="let item of missaCheckbox; let i = index" style="font-size:1.2em; padding:5px">
    <div class="custom-control custom-checkbox">
      <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'missaCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado" [value]="vlr?.valor" (change)="aMissaSoma($event, 'nao',item?.posicao)">
        <label class="custom-control-label" [for]="i+'missaCheck'" style="cursor: pointer;">
          {{item?.titulo}}<br>
          <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
        </label>
    </div>
    <br>
  </div>
    <div class="stepperButtons">
        <button class="btn btn-dark" matStepperPrevious (click)="toTop()">Anterior</button>
        &nbsp;&nbsp;
        <button class="btn btn-info" matStepperNext (click)="toTop()">Próximo</button>
      </div>
      </form>             
  </mat-step>  
  <mat-step [stepControl]="colacaoGrau" *ngIf="checkColacao">
        <div style="text-align: center;color:#ff2c96">
          <h2>Colação de Grau</h2>
        </div>
        <div class="row fotoGaleria">
          <div class="col">
            <img [src]="fotoColacao">
          </div>
        </div>  
      <form>
  <!-- SELECT OPTION -->
  <div *ngFor="let item of colacaoSelecionavel">
    <label>{{item?.titulo}}</label>
    <select (change)="aColacaoSomaSelecionavel($event,item?.posicao)" class="form-control">
      <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
        <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
          <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
          <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
        </optgroup>
    </select>
  <div class="descricaoSelecionavel">{{itemOpt?.descricao | replace}}</div>
  <hr>
  </div>
  <!-- CHECKBOX -->
  <div *ngFor="let item of colacaoCheckbox; let i = index" style="font-size:1.2em; padding:5px">
    <div class="custom-control custom-checkbox">
      <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'colacaoCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado" [value]="vlr?.valor" (change)="aColacaoSoma($event, 'nao',item?.posicao)">
        <label class="custom-control-label" [for]="i+'colacaoCheck'" style="cursor: pointer;">
          {{item?.titulo}}<br>
          <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
        </label>
    </div>
    <br>
  </div>
         <div class="stepperButtons">
            <button class="btn btn-dark" matStepperPrevious (click)="toTop()">Anterior</button>
            &nbsp;&nbsp;
            <button class="btn btn-info" matStepperNext (click)="toTop()">Próximo</button>
          </div>
      </form>             
  </mat-step>
  <mat-step [stepControl]="jantarDancante" *ngIf="checkjantar">
    <div style="text-align: center;color:#ff2c96">
      <h2>Jantar Dançante</h2>
    </div>
    <div class="row fotoGaleria">
      <div class="col">
        <img [src]="fotoJantar">
      </div>
    </div> 
    <form>
      <label>Local Jantar Dançante</label>
      <select (change)="selecionarLocalJantar($event)" class="form-control" [ngStyle]="{'border-color': !localJantar ? '#dd443e' : '#3cb42c'}">
        <option disabled selected value> -- Escolher -- </option>
        <optgroup *ngFor="let local of listaEventos">
          <option [value]="local?._id">{{local?.nome}}</option>
          <!-- <option disabled style="font-size: 0.8em;">{{local?.descricao | replace}} </option> -->
        </optgroup>
      </select>
      <div class="descricaoSelect">
         {{ localJantarSelecionado?.descricao | replace}}
      </div>
      <br>
  <!-- SELECT OPTION -->
  <div *ngFor="let item of jantarSelecionavel">
    <label>{{item?.titulo}}</label>
    <select (change)="aJantarSomaSelecionavel($event,item?.posicao)" class="form-control">
      <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
          <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
          <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
          <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
        </optgroup>
    </select>
  <div class="descricaoSelecionavel">{{itemOpt?.descricao | replace}}</div>
  <hr>
  </div>
  <!-- CHECKBOX FIXOS-->
  <div *ngFor="let item of fixosJantar; let i = index" style="font-size:1.2em; padding:5px">
    <div class="custom-control custom-checkbox">
      <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'jantarFixo'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado" [value]="vlr?.valor" (change)="somarFixosJantar($event,'nao',item?.posicao)">
        <label class="custom-control-label" [for]="i+'jantarFixo'" style="cursor: pointer;">
          {{item?.titulo}}<br>
          <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
        </label>
    </div>
    <br>
  </div>
  <!-- CHECKBOX -->
  <div *ngFor="let item of jantarCheckbox; let i = index" style="font-size:1.2em; padding:5px">
    <div class="custom-control custom-checkbox">
      <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'jantarCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado" [value]="vlr?.valor" (change)="aJantarSoma($event, 'nao',item?.posicao)">
        <label class="custom-control-label" [for]="i+'jantarCheck'" style="cursor: pointer;">
          {{item?.titulo}}<br>
          <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
        </label>
    </div>
    <br>
  </div>
    <div class="stepperButtons">
        <button class="btn btn-dark" matStepperPrevious (click)="toTop()">Anterior</button>
        &nbsp;&nbsp;
        <button class="btn btn-info" matStepperNext [disabled]="!localJantar" (click)="toTop()">Próximo</button>
      </div>
      </form>             
  </mat-step>
  <mat-step [stepControl]="baileGala" *ngIf="checkBaile">
    <div style="text-align: center;color:#ff2c96">
      <h2>Baile de Gala</h2>
    </div>
    <div class="row fotoGaleria">
      <div class="col">
        <img [src]="fotoBaile">
      </div>
    </div> 
        <!-- LOCAL DO BAILE -->
        <label>Local Baile de Gala</label>
        <select (change)="selecionarLocalBaile($event)" class="form-control" [disabled]="detalhes.get('diasEvento').value == 1 && this.localJantar">
          <option disabled selected value> -- Escolher -- </option>
          <option *ngFor="let local of listaEventos" [value]="local?._id">
            {{local?.nome}}
          </option>
          <option *ngIf="baileDeGala != ''" selected disabled>{{baileDeGala}}</option>
        </select>
      <br>
        <!-- ASSESSORIA CERIMONIAL -->
          <label>Assessoria e Cerimonial</label>
            <select (change)="selecionarAssessoria($event)" class="form-control">
              <option disabled selected value=""> -- Escolher -- </option>
              <option [value]="vlr?.valor" *ngFor="let vlr of (assessoria?.opcoes | cidadeFilter: cidade)">
                    {{vlr?.nome}}
                </option>
            </select>
            <br>
        <!-- INICIO: FINGER FOODS / BAR TEMATICO / PROLONGAMENTO -->    
            <label>Finger Foods</label>          
            <select (change)="somarFingerFoodsBaile($event,998)" class="form-control">
              <option disabled selected value> -- Escolher -- </option>
              <option value=0>Nenhum</option>
            <optgroup>
              <option value=1>1 Tipo</option>
              <option disabled style="font-size: 0.8em;">Finger Food: Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.</option>
            </optgroup>
            <optgroup>
              <option value=2>2 Tipos</option>
              <option disabled style="font-size: 0.8em;">Finger Food: Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.</option>
            </optgroup>
            <optgroup>
              <option value=3>3 Tipos</option>
              <option disabled style="font-size: 0.8em;">Finger Food: Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.</option>
            </optgroup>
            <optgroup>
              <option value=4>4 Tipos</option>
              <option disabled style="font-size: 0.8em;">Finger Food: Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.</option>
            </optgroup>
            <optgroup>
              <option value=5>5 Tipos</option>
              <option disabled style="font-size: 0.8em;">Finger Food: Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.</option>
            </optgroup>
            <optgroup>
              <option value=6>6 Tipos</option>
              <option disabled style="font-size: 0.8em;">Finger Food: Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.</option>
            </optgroup>
            <optgroup>
              <option value=7>7 Tipos</option>
              <option disabled style="font-size: 0.8em;">Finger Food: Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.</option>
            </optgroup>
            <optgroup>
              <option value=8>8 Tipos</option>
              <option disabled style="font-size: 0.8em;">Finger Food: Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.</option>
            </optgroup>
            <optgroup>
              <option value=9>9 Tipos</option>
              <option disabled style="font-size: 0.8em;">Finger Food: Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.</option>
            </optgroup>
            <optgroup>
              <option value=10>10 Tipos</option>
              <option disabled style="font-size: 0.8em;">Finger Food: Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.</option>
            </optgroup>
            </select>
            <br>
            <label>Bar Temático</label>          
              <select (change)="somarBarTematicoBaile($event,997)" class="form-control">
                <option disabled selected value> -- Escolher -- </option>
                <option value="0">Nenhum</option>
                <optgroup>
                  <option value=1>1</option>
                  <option disabled style="font-size: 0.8em;">Bar temático servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma)</option>
                  <option disabled style="font-size: 0.8em;">Bares com: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos.</option>
                </optgroup>
                <optgroup>
                  <option value=2>2</option>
                  <option disabled style="font-size: 0.8em;">Bar temático servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma)</option>
                  <option disabled style="font-size: 0.8em;">Bares com: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos.</option>
                </optgroup>
                <optgroup>
                  <option value=3>3</option>
                  <option disabled style="font-size: 0.8em;">Bar temático servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma)</option>
                  <option disabled style="font-size: 0.8em;">Bares com: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos.</option>
                </optgroup>
                <optgroup>
                  <option value=4>4</option>
                  <option disabled style="font-size: 0.8em;">Bar temático servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma)</option>
                  <option disabled style="font-size: 0.8em;">Bares com: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos.</option>
                </optgroup>
                <optgroup>
                  <option value=5>5</option>
                  <option disabled style="font-size: 0.8em;">Bar temático servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma)</option>
                  <option disabled style="font-size: 0.8em;">Bares com: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos.</option>
                </optgroup>
                <optgroup>
                  <option value=6>6</option>
                  <option disabled style="font-size: 0.8em;">Bar temático servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma)</option>
                  <option disabled style="font-size: 0.8em;">Bares com: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos.</option>
                </optgroup>
                <optgroup>
                  <option value=7>7</option>
                  <option disabled style="font-size: 0.8em;">Bar temático servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma)</option>
                  <option disabled style="font-size: 0.8em;">Bares com: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos.</option>
                </optgroup>
                <optgroup>
                  <option value=8>8</option>
                  <option disabled style="font-size: 0.8em;">Bar temático servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma)</option>
                  <option disabled style="font-size: 0.8em;">Bares com: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos.</option>
                </optgroup>
                <optgroup>
                  <option value=9>9</option>
                  <option disabled style="font-size: 0.8em;">Bar temático servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma)</option>
                  <option disabled style="font-size: 0.8em;">Bares com: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos.</option>
                </optgroup>
                <optgroup>
                  <option value=10>10</option>
                  <option disabled style="font-size: 0.8em;">Bar temático servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma)</option>
                  <option disabled style="font-size: 0.8em;">Bares com: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos.</option>
                </optgroup>
            </select>
            <br>
              <label>Prolongamento do Evento (After)</label>          
              <select (change)="somarProlongamentoEventoBaile($event,999)" class="form-control">
                <option disabled selected value> -- Escolher -- </option>
                <option value="0">Nenhum</option>
                  <option value="1">1 Hora</option>
                  <option value="2">2 Horas</option>
                  <option value="3">3 Horas</option>
                  <option value="4">4 Horas</option>
                  <option value="5">5 Horas</option>
                  <option value="6">6 Horas</option>
              </select>
              <br>
        <!-- FIM: FINGER FOODS / BAR TEMATICO / PROLONGAMENTO -->
        <!-- SELECT OPTION -->
  <div *ngFor="let item of baileGalaSelecionavel">
    <label>{{item?.titulo}}</label>
    <select (change)="aBaileSomaSelecionavel($event,item?.posicao)" class="form-control">
      <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
      <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
       <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
       <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
      </optgroup>
    </select>
  <div class="descricaoSelecionavel">{{itemOpt?.descricao | replace}}</div>
  <hr>
  </div>
  <!-- INICIO: CAFÉ DA MANHÃ / CARRINHO DE CHOPP / BEBIDAS AFTER -->
  <div style="font-size:1.2em; padding:5px">
    <div class="custom-control custom-checkbox">
      <input class="custom-control-input" id="cafeManha" type="checkbox" (change)="somarCafeManhaEventoBaile($event,999)" value="3.5">
        <label class="custom-control-label" for="cafeManha" style="cursor: pointer;">
          Café da Manhã<br>
          <span style="font-size:0.85em; color:rgb(98, 98, 98)">Café da manhã tradicional: oferecido das 5hrs às 7hrs, é preferível as opções mais leves, como pães, café, leite, bolos e frutas</span>
        </label>
    </div>
    <br>
  </div>
  <div style="font-size:1.2em; padding:5px">
    <div class="custom-control custom-checkbox">
      <input class="custom-control-input" id="carroChopp" type="checkbox" (change)="somarCarrinhoChoppEventoBaile($event,999)"  value="5">
        <label class="custom-control-label" for="carroChopp" style="cursor: pointer;">
          Carrinho de Chopp<br>
          <span style="font-size:0.85em; color:rgb(98, 98, 98)">Carrinho de Chopp</span>
        </label>
    </div>
    <br>
  </div>
  <div style="font-size:1.2em; padding:5px">
    <div class="custom-control custom-checkbox">
      <input class="custom-control-input" id="bebidasAfter" type="checkbox" (change)="somarBebidasAfterEventoBaile($event,999)"  value="11">
        <label class="custom-control-label" for="bebidasAfter" style="cursor: pointer;">
          Bebidas Prolongamento<br>
          <span style="font-size:0.85em; color:rgb(98, 98, 98)">Bebidas Prolongamento</span>
        </label>
    </div>
    <br>
  </div>
  <!-- FIM: CAFÉ DA MANHÃ / CARRINHO DE CHOPP / BEBIDAS AFTER -->
  <!-- CHECKBOX FIXOS-->
  <div *ngFor="let item of fixosBaile; let i = index" style="font-size:1.2em; padding:5px">
    <div class="custom-control custom-checkbox">
      <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'baileFixo'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado" [value]="vlr?.valor" (change)="somarFixosBaile($event,'nao',item?.posicao)">
        <label class="custom-control-label" [for]="i+'baileFixo'" style="cursor: pointer;">
          {{item?.titulo}}<br>
          <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
        </label>
    </div>
    <br>
  </div>
  <!-- CHECKBOX -->
  <div *ngFor="let item of baileGalaCheckbox; let i = index" style="font-size:1.2em; padding:5px">
    <div class="custom-control custom-checkbox">
      <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'baileCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado" [value]="vlr?.valor" (change)="aBaileGalaSoma($event, 'nao',item?.posicao)">
        <label class="custom-control-label" [for]="i+'baileCheck'" style="cursor: pointer;">
          {{item?.titulo}}<br>
          <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
        </label>
    </div>
    <br>
  </div>
    <div class="stepperButtons">
      <button class="btn btn-dark" matStepperPrevious (click)="toTop()">Anterior</button>
      &nbsp;&nbsp;
      <button class="btn btn-success" matStepperNext (click)="salvarOrcamento()">Finalizar</button>
    </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>